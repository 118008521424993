import React from 'react';

import {
    Link,
    useI18next,
} from "gatsby-plugin-react-i18next"


import styled from "styled-components"
import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';

import SectionHeader from './section-header';
import backgroundImage from '../images/AdobeStock_283607274_Preview_cut_blue.jpg';
import Button from '@material-ui/core/Button';
import Box from '@material-ui/core/Box';
import { Grid } from '@material-ui/core';


const Section = styled.section`
    width: 100%;
    padding-top: 90px;
    text-align: center;
    background-color: #ffffff;
    background-image: url(${props => props.$backgroundImage});
    // background-attachment: fixed;
    // background-size: cover;
`

// const Container = styled.div`
//     width: 1372px;
//     margin: 0 auto;
//     max-width: 90%;
// `

const ContentWrapper = styled.div`
    width: 1180px;
    margin: 0 auto;
    max-width: 90%;
    text-align: center;
`


const Content = styled.div`
    // margin: 25px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    padding-bottom: 50px;
    padding-top: 25px;
`


const StyledButton = styled(Button)`
    margin: 8px;
    width: 500px;
    display: block;
    font-size: 20px;
    
    @media screen and (max-width: 1220px) {
        width: auto;
        font-size: 16px;
    }
`

const useStyles = makeStyles((theme) =>
    createStyles({
        button: {
            margin: theme.spacing(1),
            width: 500,
            display: 'block',
            fontSize: 20,
        },

    }),
);


const OurServices = (props) => {
    const { t } = useI18next();
    const classes = useStyles();

    return (
        <React.Fragment>
            <Section id="test-yourself-home">
                <Container>
                    <SectionHeader title={props.title} subtitle={props.subtitle} withBackground={false} />
                    <Content>
                        <Box display="flex" flexDirection="column" alignItems="center" mt={5}>
                            <Link to="/test-yourself/are-you-biased"><StyledButton variant="contained" color="primary" className={classes.button}>{t("MENU.ARE-YOU-BIASED")}</StyledButton></Link>
                            <Link to="/test-yourself/test-your-cultural-awareness"><StyledButton variant="contained" color="primary" className={classes.button}>{t("MENU.TEST-YOUR-CULTURAL-AWARENESS")}</StyledButton></Link>
                            <Link to="/test-yourself/find-your-companys-behavioural-type"><StyledButton variant="contained" color="primary" className={classes.button}>{t("MENU.FIND-YOUR-COMPANYS-BEHAVIOURAL-TYPE")}</StyledButton></Link>
                        </Box>
                    </Content>
                </Container>
            </Section>
        </React.Fragment>
    )
}


export default OurServices