import React, { useEffect, useRef, useState } from 'react';
import { AnchorLink } from "gatsby-plugin-anchor-links";

import {
    Link,
    Trans,
    useTranslation,
    useI18next,
    I18nextContext
} from "gatsby-plugin-react-i18next"

import { graphql, useStaticQuery } from "gatsby"
import Img from "gatsby-image"
import Avatar from '@material-ui/core/Avatar';
import Divider from '@material-ui/core/Divider';
import FormatQuoteIcon from '@material-ui/icons/FormatQuote';

import styled from "styled-components"
import { gsap } from "gsap"
import { Parallax, withController } from 'react-scroll-parallax';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const Section = styled.section`
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 100vh;
    min-height: 700px;
    max-width: 100%;
    // background-color: #000;
    // background-color: rgba(234,241,243,0.85);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: 50%;
    margin-top: -131px;
    
    @media screen and (max-width: 1220px) {
        overflow: inherit;
        margin-top: 0;
        min-height: initial;
    }
`

const BackgroundImage = styled(Img)`
    position: relative;
    min-height: 700px;
    min-height: 100vh;
    z-index: -1;
    
    @media screen and (max-width: 1100px) {
        height: 100vh;
        margin-top: 68px;
    }
    
    @media screen and (max-width: 1100px) and (orientation: landscape) {
        min-height: 1100px;
    }
    &::after {
        position: absolute;
        content: "";
        background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='4' height='4' viewBox='0 0 4 4'%3E%3Cpath fill='%2321409a' fill-opacity='0.4' d='M1 3h1v1H1V3zm2-2h1v1H3V1z'%3E%3C/path%3E%3C/svg%3E");
        background: white;
        width: 100%;
        height: 100%;
        top: 0;
        left: 0;
        opacity: 0.3;
    }
`

const TextWrapper = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    width: 1372px;
    max-width: 90%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    left: 50%;
    transform: translateX(-50%);
    padding-top: 131px;
    
    @media screen and (max-width: 1220px) {
        max-width: 95%;
        padding-top: 73px;
        padding-top: 0;
        height: 100vh;
        justify-content: space-evenly;
        
        justify-content: center;
        justify-content: flex-start;
    }
    
    @media screen and (max-width: 1220px) and (min-height: 1024px) {
        justify-content: center;
    }
    
        
    @media screen and (max-width: 1100px) and (orientation: landscape) {
        justify-content: flex-start;
    }
`
const TextWrapperInnerContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    justify-content: space-evenly;
`


const TextWrapperInner = styled.div`
    display: flex;
    align-items: center;
    margin-top: 8px;
    margin-top: 0;
    margin-bottom: 16px;
    margin-bottom: 0;
    // margin-bottom: 25px;
    // padding-bottom: 30px;
    white-space: pre-line;
    
    @media screen and (max-width: 1220px) {
        margin-bottom: 16px;
        margin-bottom: 25px;
        margin-bottom: 0;
        flex-direction: column;
        
        &.reverse {
            flex-direction: column-reverse;
        }
    }
     
    h2 {
        font-size: 40px;
        text-transform: uppercase;
        
        @media screen and (max-width: 1220px) {
            font-size: 34px;
            text-align: center;
        }
        
        @media screen and (max-width: 767px) {
            font-size: 24px;
            text-align: center;
        }
        
        @media screen and (max-width: 575px) {
            font-size: 20px;
            text-align: center;
        }

        @media screen and (max-width: 420px) {
            font-size: 18px;
            text-align: center;
        }
    }
`

const TextWrapperInnerWelcome = styled(TextWrapperInner)`
    padding-top: 20px;
    margin-bottom: 10px;
    margin-top: 10px;
     @media screen and (max-width: 1220px) {
        padding-top: 0;
        margin-bottom: 0;
        margin-top: 10px;
        padding-bottom: 0;
     }
`

const Text = styled.h3`
    position: relative;
    display: inline;
    font-size: 40px;
    font-size: clamp(25px, 3.8vw, 36px);
    font-size: 28px;
    
    // width: 1180px;
    // max-width: 90%;
    // width: 61.8%;
    line-height: 1.2;
    margin-bottom: 30px;
    font-weight: 400;
    overflow: visible;
    // float: left;
    
    // &.text-to-the-left {
    //     text-align: left;
    // }
    
    // &.text-to-the-right {
    //     text-align: right;
    // }
    
    span {
        display: inline-block;
    }
    
    
    // &.ef {
    //     &::before {
    //         position: absolute;
    //         font-family: 'Material Icons';
    //         content: "format_quote";
    //         -webkit-font-feature-settings: 'liga';
    //         left: -36px;
    //         top: -4px;
    //     }
    // }
    
    // &.sv {
    //     &::before {
    //         position: absolute;
    //         font-family: 'Material Icons';
    //         content: "format_quote";
    //         -webkit-font-feature-settings: 'liga';
    //         left: -36px;
    //         top: -4px;
    //     }
    // }
    

    
    // &::after {
    //     position: absolute;
    //     font-family: 'Material Icons';
    //     content: "format_quote";
    //     -webkit-font-feature-settings: 'liga';
    // }
    
    @media screen and (max-width: 1220px) {
        font-size: 33px;
        // font-size: 42px;
        
        &.langhu {
            font-size: 33px;
        }
    }
    
    @media screen and (max-width: 1100px) {
        font-size: 29px;
        // font-size: 42px;
        
        &.langhu {
            font-size: 29px;
        }
    }
    
    @media screen and (max-width: 900px) {
        font-size: 35px;
        // font-size: 42px;
        
        &.langhu {
            font-size: 35px;
        }
    }
    
    @media screen and (max-width: 700px) {
        font-size: 22px;
        font-size: 40px;
        font-size: 26px;
        
        &.langhu {
            font-size: 40px;
            font-size: 26px;
        }
    }
    
    @media screen and (max-width: 575px) {
        font-size: 18px;
        
        &.langhu {
            font-size: 18px;
        }
    }
    
        
    @media screen and (max-width: 1100px) and (orientation: landscape) {
        font-size: 18px;
        
        &.langhu {
            font-size: 18px;
        }
    }
`

const Bold = styled.span`
    font-weight: 700;
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    cursor: pointer;
    // width: 38.2%;
    // margin: 25px;
    // float: right;
    
    &.to-the-right {
        margin-left: 24px;
        margin-right: 24px;
        
        @media screen and (max-width: 1220px) {
            margin-left: 0;
            margin-right: 12px;
            margin-top: 12px;
            margin-top: 0;
            flex-direction: row;
            align-self: flex-start;
        }
    }
    
    &.to-the-left {
        margin-right: 24px;
        margin-left: 24px;
        
        @media screen and (max-width: 1220px) {
            margin-left: 12px;
            margin-right: 0;
            margin-top: 12px;
            margin-top: 0;
            flex-direction: row-reverse;
            align-self: flex-end;
        }
    }
`

const StyledAvatar = styled(Avatar)`
    width: 160px;
    height: 160px;
    margin-bottom: 5px;
    
    @media screen and (max-width: 1400px) {
        height: 130px;
        width: 130px;
        margin-left: 8px;
        margin-right: 8px;
    }

    
    @media screen and (max-width: 1220px) {
        height: 100px;
        width: 100px;
        margin-left: 8px;
        margin-right: 8px;
    }
        
    @media screen and (max-width: 767px) {
        height: 80px;
        width: 80px;
        margin-left: 8px;
        margin-right: 8px;
    }
        
    @media screen and (max-width: 575px) {
        height: 70px;
        width: 70px;
        margin-left: 8px;
        margin-right: 8px;
    }
    
        
    @media screen and (max-width: 1100px) and (orientation: landscape) {
        height: 60px;
        width: 60px;
    }
`

const Name = styled.h4`
    font-size: 18px;
    text-transform: uppercase;
    margin-top: 5px;
    @media screen and (max-width: 1400px) {
        margin-top: 0;
        font-size: 16px;
        text-align: center;
    }
    
    @media screen and (max-width: 1220px) {
        margin-top: 0;
        font-size: 16px;
        text-align: center;
    }
    
    @media screen and (max-width: 767px) {
        margin-top: 0;
        font-size: 18px;
        text-align: center;
    }
`

const Titles = styled.p`
    font-size: 16px;
`

const Title = styled.p`
    &&& {
        font-size: 18px;
        margin-top: 0;
        &.align-center {
            text-align: center;
        }
    }
`

const StyledFormatQuoteIcon = styled(FormatQuoteIcon)`
    position: relative;
    font-size: 27px;
    top: -2px;
    @media screen and (max-width: 1220px) {
        font-size: 27px;
    }
    
    @media screen and (max-width: 910px) {
        font-size: 27px;
    }
    
    @media screen and (max-width: 767px) {
        font-size: 24px;
    }
    
    @media screen and (max-width: 575px) {
        font-size: 16px;
    }
    
    @media screen and (max-width: 1100px) and (orientation: landscape) {
        font-size: 16px;
    }
`

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(44, 70, 107, 0.3)',
        textAlign: 'left',
        // fontSize: 18,
        '& p': {
            textAlign: 'left'
        }
    },
    paper: {
        position: 'relative',
        maxWidth: 750,
        maxHeight: '80vh',
        overflowY: 'scroll',
        marginTop: 55,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),

        '&::-webkit-scrollbar': {
            display: 'none'
        },

        '&:focus': {
            outline: 0,
        },
    },

    modalHeader: {
        display: 'flex',
    },

    modalHeaderInner: {
        marginLeft: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    modalTitle: {
        '&&': {
            textAlign: 'left',
        }
    },
    closeIcon: {
        position: 'absolute',
        right: 16,
        cursor: 'pointer',

        '&:hover': {
            color: '#ff8000',
        }
        // right: 0,
    }

}));

const Welcome = (props) => {
    // const { t, i18n } = useTranslation();
    const { t } = useI18next();
    const [openSandy, setOpenSandy] = React.useState(false);
    const [openFrici, setOpenFrici] = React.useState(false);


    const classes = useStyles();

    const context = React.useContext(I18nextContext);
    const language = context.language;
    const [showText, setShowText] = useState(false)

    let welcome = useRef(null);
    let sandyQuote = useRef(null);
    let frigyesQuote = useRef(null);
    let sandyAvatar = useRef(null);
    let frigyesAvatar = useRef(null);

    const handleSandyOpen = () => {
        setOpenSandy(true)
    }

    const handleFriciOpen = () => {
        setOpenFrici(true)
    }

    const handleSandyClose = () => {
        setOpenSandy(false)
    }

    const handleFriciClose = () => {
        setOpenFrici(false)
    }


    useEffect(() => {
        gsap.from([welcome.current, frigyesAvatar.current, frigyesQuote.current, sandyQuote.current, sandyAvatar.current], {
            autoAlpha: 0,
            y: 150,
            opacity: 0,
            duration: 1,
            delay: 0.5,
            stagger: 0.3,
        })
    }, [])



    const data = useStaticQuery(graphql`
        query {
          background: file(relativePath: {eq: "code-breakers-hero.jpg"}) {
            childImageSharp {
              # Specify the image processing specifications right in the query.
              # Makes it trivial to update as your page's design changes.
              fluid(maxWidth: 1920, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          sandy: file(relativePath: {eq: "sandy-vaci.jpg"}, publicURL: {}) {
            id
            publicURL
          }
          frigyes: file(relativePath: {eq: "endersz--frigyes.jpg"}, publicURL: {}) {
            id
            publicURL
          }
          ourTeam: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(our-team)/"  }} sort: {fields: frontmatter___weight, order: DESC}) {
            edges {
              node {
                frontmatter {
                  name
                  nick
                  title
                  titles
                  language
                }
                html
                id
              }
            }
          }
        }
    `)

    let team = data.ourTeam.edges
    let teamFilteredByLanguage = team.filter(member => member.node.frontmatter.language == language)

    return (
        <Section>
            <Parallax y={[-25, 20]} disabled={false} >
                <BackgroundImage
                    fluid={data.background.childImageSharp.fluid}
                    alt="CodeBreakers image"
                    // style={{zIndex: -1, minHeight: 700}}
                    onLoad={() => setShowText(true)}
                />
            </Parallax>
            <TextWrapper>
                <TextWrapperInnerWelcome>
                    <h2 ref={welcome}>{t("INDEX.WELCOME")}</h2>
                </TextWrapperInnerWelcome>

                <TextWrapperInnerContainer>
                    <TextWrapperInner>
                        <div className="sv text-align-right"><span className="tempHide" ref={frigyesQuote}><StyledFormatQuoteIcon /><Text className={`lang${language}`} dangerouslySetInnerHTML={{ __html: t("INDEX.FRIGYES-QUOTE", { interpolation: { escapeValue: false } }) }} /><StyledFormatQuoteIcon /></span></div>
                        <AvatarWrapper className="to-the-left tempHide" ref={frigyesAvatar} onClick={handleFriciOpen}>
                            <StyledAvatar src={data.frigyes.publicURL} />
                            <Name>{t("INDEX.FRIGYES-ENDERSZ")}</Name>
                            {/*<Titles>Professor, author, executive mentor, board member, senior advisor, business owner</Titles>*/}
                        </AvatarWrapper>
                    </TextWrapperInner>

                    <TextWrapperInner className="reverse">
                        <AvatarWrapper className="to-the-right tempHide" ref={sandyAvatar} onClick={handleSandyOpen}>
                            <StyledAvatar src={data.sandy.publicURL} />
                            <Name>{t("INDEX.SANDY-VACI")}</Name>
                            {/*<Titles>Professor, author, executive mentor, board member, senior advisor, business owner</Titles>*/}
                        </AvatarWrapper>
                        <div className="ef text-align-left"><span className="tempHide" ref={sandyQuote}><StyledFormatQuoteIcon /><Text className={`lang${language}`} dangerouslySetInnerHTML={{ __html: t("INDEX.SANDY-QUOTE", { interpolation: { escapeValue: false } }) }} /><StyledFormatQuoteIcon /></span></div>
                    </TextWrapperInner>
                </TextWrapperInnerContainer>

                {
                    teamFilteredByLanguage.map((member, index) => {
                        if (member.node.frontmatter.nick == "sandy" || member.node.frontmatter.nick == "frici") {
                            return (
                                <Modal
                                    key={member.node.frontmatter.nick}
                                    aria-labelledby="transition-modal-title"
                                    aria-describedby="transition-modal-description"
                                    className={classes.modal}
                                    open={member.node.frontmatter.nick == "sandy" ? openSandy : openFrici}
                                    onClose={member.node.frontmatter.nick == "sandy" ? handleSandyClose : handleFriciClose}
                                    closeAfterTransition
                                    BackdropComponent={Backdrop}
                                    BackdropProps={{
                                        timeout: 500,
                                    }}
                                >
                                    <Fade in={member.node.frontmatter.nick == "sandy" ? openSandy : openFrici}>
                                        <div className={classes.paper}>
                                            <CloseIcon className={classes.closeIcon} onClick={member.node.frontmatter.nick == "sandy" ? handleSandyClose : handleFriciClose} />
                                            <div className={classes.modalHeader}>
                                                <StyledAvatar src={member.node.frontmatter.nick == "sandy" ? data.sandy.publicURL : data.frigyes.publicURL} />
                                                <div className={classes.modalHeaderInner}>
                                                    <Name>{member.node.frontmatter.name}</Name>
                                                    <Title>{member.node.frontmatter.title}</Title>
                                                    <Titles className={classes.modalTitle}>{member.node.frontmatter.titles}</Titles>
                                                </div>
                                            </div>
                                            <div dangerouslySetInnerHTML={{ __html: member.node.html }} />
                                        </div>
                                    </Fade>
                                </Modal>
                            )
                        }

                    })
                }
            </TextWrapper>
        </Section>
    )
}

export default Welcome;