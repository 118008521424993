import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import {
    Link,
    Trans,
    useTranslation,
    useI18next,
    I18nextContext
} from "gatsby-plugin-react-i18next"


import { Content, ContainerWithBackground, ContentInner } from '../styles/global-styles';

import { createStyles, Theme, makeStyles } from '@material-ui/core/styles';
import styled from "styled-components"

import SectionHeader from './section-header';
import Avatar from '@material-ui/core/Avatar';
import Box from '@material-ui/core/Box';

import SimpleCard from '../components/our-model-mini-card'

const Container = styled.div`
    width: 100%;
    // padding-top: 60px;
    text-align: center;
    // background: #ACD433;
    // background: #2c466b;
    // color: wheat;
    // margin-top: 15px;
    margin: 0 auto;
    background-image: url(${(props) => props.backgroundImage});
`

const Section = styled.div`
    
`


const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props => props.$backgroundColour};
    background-color: white;
    color: ${props => props.$colour};
    padding-top: 60px;
    padding-bottom: 60px;
    z-index: 1;
    position: relative;
`

// const ContentInner = styled.div`
//     width: 73.306%;
//     margin: 0 auto;
// `

// const Content = styled.div`
//     margin: 0 auto;
//     margin-top: 40px;
//     max-width: 90%;

//     p { 

//         font-size: 20px;
//         line-height: 1.4;
//         margin-top: 14px;
//         font-weight: 400;
//         white-space: pre-wrap;
//         margin-bottom: 14px;
//         text-align: left;
//     }
// `

const CustomContent = styled(Content)`
    min-height: auto;
`

const CustomContainerWithBackground = styled(ContainerWithBackground)`
    min-height: auto;
`

const AvatarsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    margin: 25px;
`

const StyledAvatar = styled(Avatar)`
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
`

const Name = styled.h4`
    font-size: 20px;
    text-transform: uppercase;
`

const Titles = styled.p`
    font-size: 16px;
    text-align: center;
`

const useStyles = makeStyles((theme) =>
    createStyles({
        cardContainer: {
            display: 'flex',
            gap: '1em',
            flexWrap: 'wrap',
            justifyContent: 'center',
            cursor: 'pointer',

            '& > *': {
                flexBasis: 230,
                minWidth: '15em',
                backgroundColor: 'wheat',
                transition: 'background 0.5s ease',

                ['@media screen and (max-width: 1340px)']: {
                    flexBasis: 300,
                },
                ['@media screen and (max-width: 767px)']: {
                    width: '100%',
                    minWidth: 'initial',
                    flexBasis: 'auto',
                },
                '& .card-title': {
                    // height: 64,
                    height: 96,

                    ['@media screen and (max-width: 1340px)']: {
                        height: 64
                    }
                },
                '& .card-text': {
                    fontSize: 19,
                },

                '&:hover': {
                    '& > *': {
                        backgroundColor: 'rgb(44,70,107)',
                        color: 'wheat',
                        transition: 'background 0.3s ease',

                        '& .card-title': {
                            color: 'wheat',
                        },

                        '& .card-button': {
                            color: 'white'
                        },

                        '& .card-text': {
                            color: 'white'
                        },

                        '& .card-title-link': {
                            '&:hover': {

                                '& .card-title': {

                                    color: 'wheat',
                                    '-webkit-text-stroke': '0',
                                    '-webkit-text-stroke-color': 'wheat',
                                    '-webkit-text-stroke-width': '0',

                                },

                            },
                        },
                        '& a': {
                            '&:hover': {
                                color: 'wheat',
                                '-webkit-text-stroke': '0',
                                '-webkit-text-stroke-color': 'wheat',
                                '-webkit-text-stroke-width': '0',
                            }
                        },
                    }
                }

            },
        },
        cardTitle: {
            fontSize: 28,
            textTransform: 'uppercase',
            marginBottom: 32,
        },

    }),
);




const OurModelContentHomePage = (props) => {

    const query = useStaticQuery(
        graphql`
      query ModelsQueryHomePage {
        allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(our-models)/"  }} sort: {fields: frontmatter___order, order: ASC}) {
          edges {
            node {
                fields {
                    slug
                    # reference
                    category
                    # postId
                    path
                    langrefpath
                }
                frontmatter {
                    title
                    language
                    excerpt
                    category
                }
            }
          }
        }
      }
    `
    )
    const classes = useStyles();

    const context = React.useContext(I18nextContext);
    const currentLanguage = context.language;
    const { t } = useI18next();

    // React.useEffect(()=>{
    //     console.log(query)
    // }, [])

    const edges = query.allMarkdownRemark.edges
    const models = edges.filter(edge => edge.node.frontmatter.language == currentLanguage)

    return (

        <CustomContainerWithBackground backgroundImage={props.backgroundImage}>
            <ContentWrapper backgroundColour="white">
                <SectionHeader colour={props.colour} dividerColour={props.dividerColour} title={props.title} subtitle={props.subtitle} subTitleColour={props.subTitleColour} />
                <CustomContent className="transparent">
                    <Box className={classes.cardContainer}>
                        {
                            models.map((model, index) => {

                                return (
                                    <SimpleCard key={index} cardNumber={`${index}`} link={model.node.fields.langrefpath} title={model.node.frontmatter.title} body={model.node.frontmatter.excerpt} />
                                )

                            })
                        }
                    </Box>
                </CustomContent>
            </ContentWrapper>
        </CustomContainerWithBackground>


    )
}

export default OurModelContentHomePage