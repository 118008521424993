import React from "react"
// import { Link } from "gatsby"
import {
  Link,
  Trans,
  useTranslation,
  useI18next,
  I18nextContext
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"

import OurModel from "../components/our-model-content-home-page"
import OurServices from "../components/our-services"
import Welcome from "../components/welcome-with-modal"
import TestYourself from "../components/test-yourself-home-page"


const IndexPage = ({location}) => {
    const context = React.useContext(I18nextContext);
    const currentLanguage = context.language;
    const {t} = useI18next();
    
    return (
      <Layout location={location}>
        <SEO title="" />
        <Welcome />
        <OurModel title={t("OUR-MODEL.TITLE")} subtitle={t("OUR-MODEL.SUBTITLE")}/>
        <OurServices title={t("OUR-SERVICES.TITLE")} subtitle={t("OUR-SERVICES.SUBTITLE")}/>
        <TestYourself title={t("TEST-YOURSELF.TITLE")} subtitle={t("TEST-YOURSELF.SUBTITLE")}/>
      </Layout>
    )  
  
}

export default IndexPage